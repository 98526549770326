import Vue from 'vue'
import VueRouter from 'vue-router'
import {getAccessToken, getOpenId, isInWeixin, setUserToPath, setInvitationCode} from '@/utils/helper'
import wechat from '@/utils/wechat'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: {name: '404'}
  },
  {
    path: '/oauth',
    component: () => import('@/views/wechat/Oauth.vue'),
    meta: {
      title: '微信授权中',
      icon: '',
      auth: false,
    }
  },
  {
    path: '/product',
    name: 'product',
    //component:{render: (e) => e("router-view")},
    component: ()=>import('@/views/product/Detail'),
    meta: {
      title: '商品详情',
      icon: '',
      auth: false,
    }
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
    name: '404',
    meta: {
      title: '404',
      icon: '',
      auth: false,
    }
  },
  {
    path: '*',
    component: () => import('@/views/404.vue'),
    meta: {
      title: 'app',
      icon: '',
      auth: false,
    }
  },{
    path: '/login',
    component: () => import('@/views/login/login.vue'),
    name: 'login',
    meta: {
      title: '登录',
      icon: '',
      auth: false,
    }
  },{
    path: '/bindCompany',
    component: () => import('@/views/login/BindCompany.vue'),
    name: 'bindCompany',
    meta: {
      title: '企业邀请码',
      icon: '',
      auth: true,
    }
  },{
        path: '/addressList',
        component: () => import('@/views/address/addressList.vue'),
        name: 'addressList',
        meta: {
            title: '收货地址',
            icon: '',
            auth: true,
        }
    },{
    path: '/addressEdit',
    component: () => import('@/views/address/addressEdit.vue'),
    name: 'addressEdit',
    meta: {
      title: '新增收货地址',
      icon: '',
      auth: true,
    }
  },{
    path: '/payResult',
    component: () => import('@/views/pay/payResult.vue'),
    name: 'payResult',
    meta: {
      title: '支付结果页面',
      icon: '',
      auth: true,
    }
  },{
    path: '/orderConfirm',
    component: () => import('@/views/order/orderConfirm.vue'),
    name: 'orderConfirm',
    meta: {
      title: '确认订单',
      icon: '',
      auth: true,
    }
  },{
    path: '/payWait/:orderId',
    component: () => import('@/views/pay/payWait.vue'),
    name: 'payWait',
    meta: {
      title: '订单详情',
      icon: '',
      auth: true,
    }
  },
  // 扫码授权页面
  {
    path: '/qr-auth/:authId(\\d+)',
    component: () => import('@/views/QrAuth.vue'),
    name: 'qrAuth',
    meta: {
      title: '操作授权',
      icon: '',
      auth: false,
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }

  // 扫码授权部分
  if(to.name=== 'qrAuth'&& !to.query.code){
    wechat.oauth('snsapi_userinfo', to.path)
  }
  // 登录的拦截器
  if(to.meta && to.meta.auth){
    if(['login', 'bindCompany'].indexOf(to.name) === -1){
      setUserToPath(to.fullPath)
    }
    if(to.query && to.query.invitationCode){
      setInvitationCode(to.query.invitationCode)
    }
    if(!getAccessToken()){
      //next({ name: 'login' })
      router.push({name: 'login'})
    }else{
      if(to.name!== 'bindCompany' && isInWeixin() && !getOpenId()){
        wechat.oauth()
      }else{
        next()
      }
    }

  }else{
    next()
  }



})

export default router
