const oauth =(scope = 'snsapi_base', redirectPath= undefined)=>{
    const appid = process.env.VUE_APP_WECHAT_APPID
    const redirectUri = getRedirectUri(redirectPath)
    const oauthUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid='+
        appid+
        '&redirect_uri='+encodeURI(redirectUri)+'&response_type=code&scope='+scope+
        '&state='+(scope === 'snsapi_base' ? 'STATE':'USERINFO')
    console.log(oauthUrl)
    window.location.href= oauthUrl
}

const getRedirectUri = (redirect='/oauth') => {
    const {protocol, host} = window.location
    return protocol +'//'+ host + redirect
}

export default {
    oauth
}
