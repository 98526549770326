const TOKEN_KEY = 'TOKEN'
const TOKEN_EXPRESS_KEY = 'TOKEN_EXPRESS'
const OPENID_KEY = 'OPENID'
const USER_TO_PATH_KEY = 'USER_TO_PATH'
const INVITATION_CODE_KEY = 'INVITATION_CODE'
const CUSTOM_WECHAT_ACCOUNT_KEY = 'CUSTOM_WECHAT_ACCOUNT'
import { getUnixTime, addHours } from 'date-fns'

// 带有超时的token
export const getAccessToken = () => {
    const token  = localStorage.getItem(TOKEN_KEY)
    if(!token){
        return null
    }
    let expressTimestamp = localStorage.getItem(TOKEN_EXPRESS_KEY)
    expressTimestamp = expressTimestamp ? parseInt(expressTimestamp): 0
    const currentTimestamp = getUnixTime(new Date())
    if(currentTimestamp>expressTimestamp){
        return null
    }
    return token
}

export const setAccessToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token)
    const expressTime = addHours(new Date(), 12)
    localStorage.setItem(TOKEN_EXPRESS_KEY, getUnixTime(expressTime).toString())
}

export const getOpenId = () => {
    const openId  = sessionStorage.getItem(OPENID_KEY)
    return openId ? openId : false
}

export const setOpenId = (token) => {
    sessionStorage.setItem(OPENID_KEY, token)
}
export const getCustomWeChatAccount = () => {
    const customWeChatAccount  = sessionStorage.getItem(CUSTOM_WECHAT_ACCOUNT_KEY)
    return customWeChatAccount ? customWeChatAccount : false
}

export const setCustomWeChatAccount = (customWeChatAccount) => {
    sessionStorage.setItem(CUSTOM_WECHAT_ACCOUNT_KEY, customWeChatAccount)
}

export const getUserToPath = () => {
    const path  = sessionStorage.getItem(USER_TO_PATH_KEY)
    return path ? path : '/'
}

export const setUserToPath = (path) => {
    //console.log(path)
    sessionStorage.setItem(USER_TO_PATH_KEY, path)
    // sessionStorage.removeItem(USER_TO_PATH_KEY)
}

export const clearUserToPath = () => {
    sessionStorage.removeItem(USER_TO_PATH_KEY)
}

export const getInvitationCode = ()=>{
    const invitationCode  = sessionStorage.getItem(INVITATION_CODE_KEY)
    return invitationCode ? invitationCode : null
}
export const setInvitationCode = (invitationCode)=>{
    sessionStorage.setItem(INVITATION_CODE_KEY, invitationCode)
}

export const isInWeixin  = () => {
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1
}
