const state= {
    productId:null,
    model:null,
    invitationCode:null,
    skuId:null,
    quantity:1,
    addressId:1,
    delivery:'onSpot',
}

const getters = {
    getProductId:(state)=> {
        return state.productId
    },
    getModel:(state) =>{
        return state.model
    },
    getInvitationCode:(state) =>{
        return state.invitationCode
    },
    getSkuId:(state) =>{
        return state.skuId
    },
    getQuantity:(state) =>{
        return state.quantity
    },
    getAddressId:(state) =>{
        return state.addressId
    },
    getDelivery:(state) =>{
        return state.delivery
    },
}

const actions = {
    updateProductId({commit}, productId){
        commit('UPDATE_PRODUCTID', productId)
    },
    updateModel({commit}, model){
        commit('UPDATE_MODEL', model)
    },
    updateInvitationCode({commit}, invitationCode){
        commit('UPDATE_INVITATIONCODE', invitationCode)
    },
    updateSkuId({commit}, skuId){
        commit('UPDATE_SKUID', skuId)
    },
    updateQuantity({commit}, quantity){
        commit('UPDATE_QUANTITY', quantity)
    },
    updateAddressId({commit}, addressId){
        commit('UPDATE_ADDRESSID', addressId)
    },
    updateDelivery({commit}, delivery){
        commit('UPDATE_DELIVERY', delivery)
    },
    async updateProduct({commit}, product){
        return new Promise((resolve) => {
            const {id: productId, model, skuId, invitationCode, quantity, addressId, delivery} = product
            commit('UPDATE_PRODUCTID', productId ? parseInt(productId): null)
            commit('UPDATE_MODEL', model)
            commit('UPDATE_INVITATIONCODE', invitationCode)
            commit('UPDATE_ADDRESSID', addressId)
            commit('UPDATE_DELIVERY', delivery)
            commit('UPDATE_SKUID', skuId ? parseInt(skuId): null)
            commit('UPDATE_QUANTITY', quantity ? (parseInt(quantity)<1? 1 : parseInt(quantity)): 1)
            return resolve(true)
        })

    }
}
const mutations = {
    UPDATE_PRODUCTID(state, productId) {
        state.productId = productId
    },
    UPDATE_ADDRESSID(state, addressId) {
        state.addressId = addressId
    },
    UPDATE_MODEL(state, model) {
        state.model = model
    },
    UPDATE_INVITATIONCODE(state, invitationCode) {
        state.invitationCode = invitationCode
    },
    UPDATE_SKUID(state, skuId) {
        state.skuId = skuId
    },
    UPDATE_QUANTITY(state, quantity) {
        state.quantity = quantity
    },
    UPDATE_DELIVERY(state, delivery) {
        state.delivery = delivery
    }
}

export default {
    namespace: true,
    state,
    getters,
    actions,
    mutations,
}
